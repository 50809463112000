import LinkInternal from 'components/LinkInternal';
import MediaGroup from 'components/MediaGroup';
import MediaLink from 'components/MediaLink';
import VideoInline from 'components/VideoInline';
import { addSearchParamsToUrl, isAnimatedLinkType } from 'helper/js/helper';
import { memo } from 'react';

type MediaGroupsProps = {
  links: ALink[];
  videos: AVideos;
  isPromotion: boolean;
  promotionId?: string;
  promotionTitle?: string;
  currentVisible: number;
  productsInWishList: string[];
  ar?: string;
};

const getMappedType = (
  displayType: ALinkDisplayTypes,
  isAnimatedGifWithProductLink: boolean,
  isAnimatedGifWithStandardLink: boolean,
) => {
  const MAPPING: IKeyValueData = {
    standard: ['link-media'],
    url: ['link-media'],
    internal: ['internal'],
    product: ['product-sidebar', 'product-wishlist'],
    productnodetails: ['product-shop', 'product-wishlist'],
    recipe: ['recipe'],
    video: ['video'],
    text: ['text'],
    textlong: ['text'],
  };

  if (isAnimatedGifWithProductLink) {
    return MAPPING['product'];
  } else if (isAnimatedGifWithStandardLink) {
    return MAPPING['standard'];
  }

  return MAPPING[displayType] || [];
};

const MediaGroups = ({
  links,
  currentVisible,
  productsInWishList,
  isPromotion = false,
  promotionId,
  promotionTitle,
  ar,
  videos = {},
}: MediaGroupsProps) => {
  return (
    <>
      {links.map((link: ALink) => {
        const {
          displayType,
          id,
          details,
          text,
          title,
          icon,
          pageNumber,
          top,
          left,
          width,
          height,
        } = link;

        const isAnimatedGif = isAnimatedLinkType(displayType);
        const isVideo = displayType === 'inline_video';
        const isAnimatedGifWithProductLink =
          isAnimatedGif && details?.destination?.displayType === 'product';
        const isAnimatedGifWithStandardLink =
          isAnimatedGif && details?.destination?.displayType === 'standard';
        const animationUrl = isAnimatedGif ? link.url : undefined;
        const url = isAnimatedGifWithStandardLink ? details?.destination.url : link.url;
        const types = getMappedType(
          displayType,
          isAnimatedGifWithProductLink,
          isAnimatedGifWithStandardLink,
        );
        const productData: AProductData | undefined = isAnimatedGifWithProductLink
          ? details?.destination.details
          : undefined;

        return (
          <MediaGroup
            key={id}
            iconsInGroup={types.length}
            currentVisible={currentVisible}
            animationUrl={animationUrl}
            displayType={displayType}
            top={top}
            left={left}
            width={width}
            hasMediaLinks={types.length > 0}
            height={height}>
            {({ calculatedWidth, relation }: { calculatedWidth: number; relation: number }) => {
              if (isVideo) {
                return (
                  <VideoInline video={videos[id]} relation={relation} width={calculatedWidth} />
                );
              }

              if (displayType === 'internal' && pageNumber) {
                return <LinkInternal page={pageNumber} />;
              }

              const params = {
                type: displayType,
                link: url ? addSearchParamsToUrl(url, { ar }) : '',
                id,
                text,
                icon,
                relation,
                groupWidth: calculatedWidth,
                title,
                isPromotion,
                promotionId,
                promotionTitle,
              };

              return types.map((mType) => (
                <MediaLink
                  key={`${id}${mType}`}
                  {...params}
                  type={mType}
                  flipped={productsInWishList.indexOf(id) > -1}
                  product={productData}
                  isAnimatedGif={isAnimatedGif}
                />
              ));
            }}
          </MediaGroup>
        );
      })}
    </>
  );
};

export default memo(MediaGroups);
