import { VIEW } from 'helper/js/constants';
import { getBaseAndUuidBeforePath } from 'helper/js/helper';
import { updateRouterParams } from 'providers/Router/actions';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, Navigate } from 'react-router-dom';
import { routes } from 'routes';

const DEFAULT_URL_PARAMETERS = {
  locale: '',
  page: 1,
  view: VIEW.FLYER,
  uuid: '',
};

export default function NoMatchContainer() {
  const quitUrl = useSelector((state: AppState) => state.appData.urls.quitUrl);
  const dispatch = useDispatch();
  let redirectUrl = '';

  useMemo(() => {
    let urlParams = {
      ...DEFAULT_URL_PARAMETERS,
    };
    try {
      const myRe = /\/(view|page|ar)\/([0-9a-zA-Z-]+)/g;
      const { uuid } = getBaseAndUuidBeforePath();
      if (!uuid) {
        throw new Error('NO UUID');
      }

      urlParams = {
        ...urlParams,
        uuid,
      };

      let matches;
      let newParams = {};

      while ((matches = myRe.exec(window.location.pathname)) !== null) {
        newParams = {
          ...newParams,
          [matches[1]]: matches[2],
        };
      }
      const redirectPath = generatePath(routes[0], urlParams);
      dispatch(updateRouterParams({ params: urlParams }));
      redirectUrl = `${redirectPath}${window.location.search}`;
    } catch (e) {
      window.location.href = quitUrl;
    }
  }, []);

  return redirectUrl ? <Navigate to={redirectUrl} replace /> : null;
}
