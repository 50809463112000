import './style.scss';

import { sleep } from 'helper/js/helper';
import { SheetContextProps, withContextProps } from 'providers/Sheet';
import { Component } from 'react';
import { compose } from 'redux';

type MediaGroupProps = {
  top: number;
  left: number;
  width: number;
  height: number;
  children({
    calculatedWidth,
    relation,
  }: {
    calculatedWidth: number;
    relation: number;
  }): JSX.Element;
  currentVisible: number;
  iconsInGroup: number;
  hasMediaLinks: boolean;
  animationUrl?: string;
  displayType: ALinkDisplayTypes;
};
type MediaGroupState = {
  relation: number;
  offsetX: number;
  offsetY: number;
  offsetHeight: number;
  offsetWidth: number;
};
class MediaGroup extends Component<MediaGroupProps & SheetContextProps, MediaGroupState> {
  static defaultProps = {
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    iconsInGroup: 1,
  };

  mounted = false;

  state = {
    offsetX: this.props.left,
    offsetY: this.props.top,
    offsetHeight: this.props.height,
    offsetWidth: this.props.width,
    relation: 1,
  };

  async componentDidUpdate(nextProps: MediaGroupProps) {
    if (this.props.currentVisible !== nextProps.currentVisible) {
      await sleep(300);
      this.rescaleMediaGroup();
    }
  }

  async componentDidMount() {
    this.mounted = true;
    this.rescaleMediaGroup();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  calculateMediaGroupDimensions = () => {
    const { width, cPageWidth, iconsInGroup, cSheetHeight } = this.props;
    const groupWidthFromPage = (width / 100) * cPageWidth;
    const linkMaxWidth = ((100 / width) * 10) / 100;
    const minWidth = linkMaxWidth * groupWidthFromPage;
    const mediaGroupMinWidth = ((minWidth * iconsInGroup) / cPageWidth) * 100;
    const mediaGroupMinHeight = (68 / cSheetHeight) * 100;
    return [mediaGroupMinWidth, mediaGroupMinHeight];
  };

  rescaleMediaGroup = () => {
    const [mediaGroupMinWidth, mediaGroupMinHeight] = this.calculateMediaGroupDimensions();

    let newState = {
      offsetX: this.props.left,
      offsetY: this.props.top,
      offsetHeight: this.props.height,
      offsetWidth: this.props.width,
      relation: 1,
    };

    if (this.props.height < mediaGroupMinHeight) {
      newState = {
        ...newState,
        offsetHeight: mediaGroupMinHeight,
        offsetY: this.props.top - (mediaGroupMinHeight - this.props.height) / 2,
      };
    }
    if (this.props.width < mediaGroupMinWidth) {
      newState = {
        ...newState,
        offsetWidth: mediaGroupMinWidth,
        offsetX: this.props.left - (mediaGroupMinWidth - this.props.width) / 2,
      };
    }
    if (this.props.top + newState.offsetHeight > 100) {
      newState = {
        ...newState,
        offsetY: 100 - newState.offsetHeight,
      };
    }
    if (this.props.left + newState.offsetWidth > 100) {
      newState = {
        ...newState,
        offsetX: 100 - newState.offsetWidth,
      };
    }
    if (this.mounted) {
      this.setState({
        ...newState,
        relation:
          ((newState.offsetWidth / 100) * this.props.cPageWidth) /
          ((newState.offsetHeight / 100) * this.props.cSheetHeight),
      });
    }
  };

  render() {
    const { offsetY, offsetX, offsetWidth, offsetHeight, relation } = this.state;
    return (
      <>
        <section
          style={{
            top: `${offsetY}%`,
            left: `${offsetX}%`,
            width: `${offsetWidth}%`,
            height: `${offsetHeight}%`,
          }}
          className={`mediagroup${
            !this.props.hasMediaLinks ? ' mediagroup--has-no-medialinks' : ''
          }`}>
          {this.props.children({
            calculatedWidth: offsetWidth,
            relation,
          })}
        </section>
        {this.props.displayType === 'animation_video' && this.props.animationUrl && (
          <video
            style={{
              top: `${this.props.top}%`,
              left: `${this.props.left}%`,
              width: `${this.props.width}%`,
              height: `${this.props.height}%`,
            }}
            className="mediagroup mediagroup--background"
            autoPlay
            muted
            loop
            playsInline>
            <source src={this.props.animationUrl} type="video/mp4" />
          </video>
        )}
        {this.props.animationUrl && (
          <div
            style={{
              backgroundImage: `url("${this.props.animationUrl}")`,
              top: `${this.props.top}%`,
              left: `${this.props.left}%`,
              width: `${this.props.width}%`,
              height: `${this.props.height}%`,
            }}
            className="mediagroup mediagroup--background"></div>
        )}
      </>
    );
  }
}

export default compose<any>(withContextProps)(MediaGroup);
