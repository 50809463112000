import './style.scss';

import ButtonC, { BUTTON_SIZE } from 'components/ButtonC/index';
import { LINK_TARGET } from 'components/Link';
import { motion } from 'framer-motion';
import { getBaseAndUuidBeforePath, getSearchParamsAsObject } from 'helper/js/helper';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { routes } from 'routes';

const replaceSearch = (routing: ARouting, searchObject: any) => {
  const searchLocation = getSearchParamsAsObject();
  const params = { ...searchLocation, ...searchObject };

  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

  const { base } = getBaseAndUuidBeforePath();
  const redirectPath = generatePath(routes[0], routing.params);
  return `${base}${redirectPath}?${queryString}`;
};

const settings = [
  {
    label: 'Wishlist',
    property: 'showwishlist',
  },
  {
    label: 'Product Details',
    property: 'showproductdetails',
  },
  {
    label: 'Search',
    property: 'showsearch',
  },
  {
    label: 'Shopping Cart',
    property: 'shoppingCart',
  },
  {
    label: 'My Account',
    property: 'myAccount',
  },
  {
    label: 'Translation Keys',
    property: 'showtranslationkeys',
  },
  {
    label: 'No Marginals',
    property: 'nomarginals',
  },
  {
    label: 'Reco Products',
    property: 'showrecommendedproducts',
  },
  {
    label: 'Notifications',
    property: 'shownotifications',
  },
  {
    label: 'PDF Download',
    property: 'enabledownload',
  },
  {
    label: 'Social',
    property: 'enablesocial',
  },
  {
    label: 'Debug Tracking',
    property: 'debugtracking',
  },
  {
    label: 'Debug',
    property: 'debug',
  },
  {
    label: 'ToggleBox',
    property: 'togglebox',
  },
];

const body = document.querySelector('body');
const initalPosition = { x: 75, y: 100 };

function ToggleBox() {
  const routing = useSelector((state: AppState) => state.routing);
  const { togglebox } = getSearchParamsAsObject();

  if (!togglebox || togglebox === 'false') {
    return null;
  }

  return (
    <motion.div
      drag
      whileTap={{ scale: 1.02 }}
      dragMomentum={false}
      dragConstraints={{ current: body }}
      className="togglebox"
      style={initalPosition}>
      <ul className="togglebox__list">
        {settings.map((setting) => (
          <li key={setting.property} className="togglebox__list-item">
            <h3>{setting.label}</h3>
            <span className="togglebox__button-on">
              <ButtonC
                size={BUTTON_SIZE.S}
                label="On"
                target={LINK_TARGET.SELF}
                href={replaceSearch(routing, { [setting.property]: true })}
              />
            </span>
            <span className="togglebox__button-off">
              <ButtonC
                size={BUTTON_SIZE.S}
                label="Off"
                target={LINK_TARGET.SELF}
                href={replaceSearch(routing, { [setting.property]: false })}
              />
            </span>
          </li>
        ))}
      </ul>
    </motion.div>
  );
}

export default memo(ToggleBox);
