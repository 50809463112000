import MediaGroups from 'components/MediaGroups';
import { MediaGroupContext } from 'containers/MediaGroupsContainer/context';
import { ProductData, wishListChangedSaga } from 'containers/WishListContainer/actions';
import { emptyArray, getVisiblePagesAmount, isAnimatedLinkType } from 'helper/js/helper';
import { useFlippyNavigate, useFlippyPageNumber } from 'helper/js/hooks/router';
import { TrackingContext } from 'providers/Tracking';
import TRACKING_EVENTS from 'providers/Tracking/constants';
import TRACKING_EVENT_COLLECTIONS from 'providers/Tracking/trackingEventCollections';
import { memo, useCallback, useContext, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { VIEW } from '../../helper/js/constants';
import { getContentId, getContentLabel, getEventInteractionType } from './trackingHelpers';

type Props = {
  pageId: string;
};

const MediaGroupsContainer = ({ pageId }: Props) => {
  const ar = useSelector(
    (state: AppState) => state.routing.params.ar,
    () => true,
  );
  const products = useSelector(
    (state: AppState) => state.appData.products,
    () => true,
  );
  const videos = useSelector(
    (state: AppState) => state.appData.videos,
    () => true,
  );
  const wishlist = useSelector((state: AppState) => state.wishlistProducts || emptyArray);
  const totalPages = useSelector((state: AppState) => state.appData.pages.length);
  const flippyPageNumber = useFlippyPageNumber();
  const isWideScreen = useSelector((state: AppState) => state.browser.isWideScreen);
  const currentVisible = getVisiblePagesAmount(flippyPageNumber, totalPages, isWideScreen);
  const [page] = useSelector((state: AppState) =>
    state.appData.pages.filter((pageItem) => pageItem.id === pageId),
  );
  const { trackEventUniversalAnalytics, trackEventDataLayerService } = useContext(TrackingContext);
  const dispatch = useDispatch();
  const flippyNavigate = useFlippyNavigate();

  const openMediaOverview = useCallback(
    (type: string, contentId: string) => flippyNavigate({ view: type, contentId }),
    [flippyNavigate],
  );

  const openProductDetailsInSidebar = useCallback(
    (contentId: number) => flippyNavigate({ view: VIEW.PRODUCTDETAILS, contentId: `${contentId}` }),
    [flippyNavigate],
  );

  const handleDeepLink = useCallback(
    (pageNumber: number) => flippyNavigate({ page: `${pageNumber}` }),
    [flippyNavigate],
  );

  const addProductToWishList = useCallback(
    (product: ProductData) => dispatch(wishListChangedSaga(product)),
    [dispatch],
  );

  const clickTracking = useCallback(
    (
      type: string,
      isAnimatedGif: boolean | undefined,
      product: AProductData | undefined,
      isInWishList: boolean | undefined,
    ) => {
      trackEventUniversalAnalytics({
        ...TRACKING_EVENTS.MEDIA_LINK_CLICKED,
        eventLabel: getContentLabel(type, isAnimatedGif),
      });

      if (type === 'product-shop') {
        trackEventDataLayerService(
          TRACKING_EVENT_COLLECTIONS.SHOPPING_LINK_BUTTON_CLICKED,
          {
            contentLabel: product?.url,
          },
          undefined,
          product ? [product] : undefined,
          { linkName: product?.title, linkURL: product?.url, linkType: 'external' },
        );
      } else {
        trackEventDataLayerService(
          TRACKING_EVENT_COLLECTIONS.MEDIA_LINK_CLICKED,
          {
            contentLabel: getContentLabel(type, isAnimatedGif),
            contentID: getContentId(type, isInWishList),
          },
          {
            eventInteractionType: getEventInteractionType(type, isInWishList),
          },
          product ? [product] : undefined,
        );
      }
    },
    [trackEventDataLayerService, trackEventUniversalAnalytics],
  );

  const onClick = useCallback(
    (
      type: string,
      linkId,
      isAnimatedGif: boolean | undefined,
      product: AProductData | undefined,
      isInWishList: boolean | undefined,
    ) => {
      const productData = product ?? products[linkId];
      const productId = parseInt(productData?.productId, 10);

      clickTracking(type, isAnimatedGif, productData, isInWishList);

      switch (type) {
        case 'product-wishlist':
          addProductToWishList({ key: productId, variant: 'default' });
          break;
        case 'product-sidebar':
          productId && openProductDetailsInSidebar(productId);
          break;
        case 'product-shop':
          break;
        default:
          openMediaOverview(type, linkId);
          break;
      }
    },
    [addProductToWishList, clickTracking, openMediaOverview, openProductDetailsInSidebar, products],
  );

  const handleLink = useCallback(
    (pageNumber: number) => {
      handleDeepLink(pageNumber);
    },
    [handleDeepLink],
  );

  let productsInWishList: string[] = [];
  let links: ALink[] = [];
  links = page.links;

  productsInWishList = page.links.reduce((all: string[], current: ALink) => {
    const foundProduct =
      isAnimatedLinkType(current.displayType) &&
      current.details?.destination?.displayType === 'product'
        ? current.details.destination.details
        : products[current.id];
    if (!foundProduct) {
      return all;
    }

    const { productId } = foundProduct;
    const isInWishList = wishlist.findIndex((productItem) => Boolean(productItem[productId])) > -1;
    if (isInWishList) {
      return [...all, current.id];
    }

    return all;
  }, []);

  const contextCallBackFunctions = useRef({ onClick, onDeepLink: handleLink });

  return (
    <MediaGroupContext.Provider value={contextCallBackFunctions.current}>
      <MediaGroups
        videos={videos}
        links={links}
        currentVisible={currentVisible}
        isPromotion={page.type === 'PROMOTION'}
        promotionId={page.promotionId}
        promotionTitle={page.promotionTitle}
        productsInWishList={productsInWishList}
        ar={ar}
      />
    </MediaGroupContext.Provider>
  );
};

export default memo(MediaGroupsContainer);
